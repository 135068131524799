export type TopicColor = {
  code: string;
};

const TopicColorTypes: Array<TopicColor> = [
  {
    code: '#6B778C'
  },
  {
    code: '#60A323'
  },
  {
    code: '#00885A'
  },
  {
    code: '#CC4488'
  },
  {
    code: '#5244AA'
  },
  {
    code: '#FF8F74'
  },
  {
    code: '#DE350D'
  },
  {
    code: '#8E542E'
  },
  {
    code: '#FF8B00'
  },
  {
    code: '#FFAB00'
  },
  {
    code: '#0053CC'
  },
  {
    code: '#2584FF'
  },
  {
    code: '#008EA6'
  },
  {
    code: '#505F79'
  }
];

const DefaultColor: TopicColor = {
  code: '#6B778C'
};

export {
  TopicColorTypes,
  DefaultColor
};
