






















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { TopicColor, TopicColorTypes, DefaultColor } from '../../types/TopicColorTypes';

@Component({
  name: 'BaseTopicColorPicker'
})
export default class BaseTopicColorPicker extends Vue {
  @Prop({ default: null }) readonly selectedColor!: string;

  topicColors: Array<TopicColor> = TopicColorTypes;

  color = this.selectedColor || DefaultColor.code;

  selectColor(topic: TopicColor): void {
    this.color = topic.code;
    this.$emit('onSelect', this.color);
  }
}
