
































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ActionMethod } from 'vuex';
import Topic from '@improve/common-utils/src/model/Topic';
import User from '@improve/common-utils/src/model/User';
import Team from '@improve/common-utils/src/model/Team';
import { BaseTopicCB } from '@improve/common-utils/src/types/BaseTopicCB';
import { MenuOption } from '@improve/common-utils/src/types/MenuOption';
import { DefaultColor } from '../../types/TopicColorTypes';
import BaseButton from '../widgets/BaseButton.vue';
import BaseAddButton from '../widgets/BaseAddButton.vue';
import BaseTeamMemberCard from '../widgets/BaseTeamMemberCard.vue';
import BaseUserSelectorModal from '../user/BaseUserSelectorModal.vue';
import BaseTextInput from '../core/BaseTextInput.vue';
import BaseTextArea from '../core/BaseTextArea.vue';
import BaseTopicColorPicker from './BaseTopicColorPicker.vue';

@Component({
  name: 'BaseTopic',
  components: {
    BaseButton,
    BaseAddButton,
    BaseTeamMemberCard,
    BaseTextInput,
    BaseTextArea,
    BaseTopicColorPicker,
    BaseUserSelectorModal
  }
})
export default class BaseTopic extends Vue {
  @Prop({ default: null }) readonly topic!: Topic;

  @Getter allTeamsById!: (id: string) => Team;

  @Action fetchTopicSubscriptions!: ActionMethod;

  @Action fetchUsersByBatch!: ActionMethod;

  private showExpertsModal = false;

  private isUserDataFetched = false;

  private allExpertIds: Array<string> = [];

  private experts: Array<User> = [];

  get buttonWidthByScreen(): string | null {
    return this.$vuetify.breakpoint.mdAndUp ? '355' : null;
  }

  async created(): Promise<void> {
    await this.fetchSubscriptions();
  }

  async fetchSubscriptions(): Promise<void> {
    if (!this.topic.id) {
      this.isUserDataFetched = true;
      return;
    }
    const subs: Map<string, number> = await this.fetchTopicSubscriptions(this.topic);
    this.allExpertIds = User.getUserIdsByRole(subs);
    this.experts = await this.fetchUsersByBatch(this.allExpertIds);
    this.isUserDataFetched = true;
  }

  userUnitName(member: User): string {
    const unit = member.unit && this.allTeamsById(member.unit);
    return (unit && unit.name) || '';
  }

  addExpertUsers(users: Array<User>): void {
    this.experts = users;
    this.showExpertsModal = false;
  }

  removeUser(user: User): void {
    this.experts = this.experts.filter((_) => _.id !== user.id);
  }

  prepareTopic(): BaseTopicCB {
    // Set default color
    if (!this.topic.metaData.color) {
      this.topic.metaData.color = DefaultColor.code;
    }
    // Set tagName for new topic
    if (!this.topic.id) {
      this.topic.tagName = (this.topic.metaData.displayName || '')
        .toLowerCase()
        .replaceAll(/[^a-zA-z0-9]/g, '-');
    }
    const newExpertsIds = this.experts.map((_) => _.id).join('-');
    const oldExpertsIds = this.allExpertIds.join('-');

    const removeExperts = this.allExpertIds.filter((_) => newExpertsIds.indexOf(_) < 0);
    const addExperts = this.experts.filter((_) => oldExpertsIds.indexOf(_.id!) < 0);
    return {
      topic: this.topic,
      experts: this.experts,
      addExperts: addExperts.map((_) => _.id) as string[],
      removeExperts
    };
  }

  memberCardOptions(): Array<MenuOption> {
    const options: Array<MenuOption> = [];
    options.push({
      title: this.$t('menu.remove').toString(),
      value: 'remove'
    });
    return options;
  }

  onMenuOptionsClick(user: User, option: MenuOption): void {
    switch (option.value) {
      case 'remove': {
        this.removeUser(user);
        break;
      }
      default: {
        break;
      }
    }
  }

  validate(): Promise<any> {
    const component: any = this.$refs.obs;
    return component.validate();
  }
}
