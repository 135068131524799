









































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { ActionMethod } from 'vuex';
import BaseTopic from '@improve/common-components/src/components/topic/BaseTopic.vue';
import BaseButton from '@improve/common-components/src/components/widgets/BaseButton.vue';
import { BaseTopicCB } from '@improve/common-utils/src/types/BaseTopicCB';
import Topic from '@improve/common-utils/src/model/Topic';
import TabContent from '@improve/common-components/src/components/helpers/TabContent.vue';

@Component({
  name: 'CreateTopic',
  components: {
    TabContent,
    BaseTopic,
    BaseButton
  }
})
export default class CreateTopic extends Vue {
  @Action createTopic!: ActionMethod;

  @Action addUserSubscription!: ActionMethod;

  @Action removeUserSubscription!: ActionMethod;

  private topic = new Topic(null, '', '');

  get baseTopicIns(): BaseTopic {
    return this.$refs.baseTopic as any;
  }

  async saveTopic(): Promise<void> {
    if (!(await this.validate())) {
      return;
    }
    const topicPayload: BaseTopicCB = this.baseTopicIns.prepareTopic();
    await this.createTopic(topicPayload.topic);

    await this.addUserSubscription({
      users: topicPayload.experts.map((_) => _.id),
      topic: topicPayload.topic
    });
    this.$router.push({ name: 'Topics' });
  }

  validate(): Promise<any> {
    return this.baseTopicIns.validate();
  }

  cancel(): void {
    this.$router.push({ name: 'Topics' });
  }
}
